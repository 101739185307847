<template>
    <div class="shadow overflow-hidden rounded border border-solid border-gray-300">
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th scope="col" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Role</th>
                    <th scope="col" title="Read" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"><font-awesome-icon icon="address-book" /></th>
                    <th scope="col" title="Write" class="p-2text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"><font-awesome-icon icon="edit" /></th>
                    <th scope="col" title="Visible" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"><font-awesome-icon icon="eye" /></th>
                    <th scope="col" title="Mandatory" class="p-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider text-center"><font-awesome-icon icon="check" /></th>
                </tr>
            </thead>
            <tbody v-if="isLoading">
                <tr>
                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="5">
                        <Loader class="my-5" height="16px" width="16px" />
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="allRoles && allRoles.length > 0">
                <tr v-for="role in allRoles" :key="role.id">
                    <td class="px-2 py-1 text-sm flex items-center gap-2"><Checkbox @input="$emit('selectAllRoles', role)" v-model="role.select_all" /> {{ role.title }}</td>
                    <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" :disabled="role.permissions.role_write || role.permissions.role_mandatory" v-model="role.permissions.role_read" />
                    </td>
                    <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" :disabled="role.permissions.role_mandatory" v-model="role.permissions.role_write" />
                    </td>
                    <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" :disabled="role.permissions.role_write || role.role_mandatory || role.role_read" v-model="role.permissions.role_visible" />
                    </td>
                    <td class="px-2 py-1 whitespace-nowrap text-center text-sm text-gray-500">
                        <Checkbox @input="$emit('selectPermission', role)" v-model="role.permissions.role_mandatory" />
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td class="px-6 py-2 whitespace-nowrap text-sm text-gray-500 text-center" colspan="5">No data available</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Loader from "@shared/loader";
import Checkbox from "@/components/checkbox";

export default {
    name: "block-and-field-permissions",
    props: {
        allRoles: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Loader,
        Checkbox,
    },
    methods: {},
    computed: {},
};
</script>

<style style="scss" scoped></style>
