<template>
    <div v-if="open" class="addblocktosection flex-col h-screen w-screen flex items-center justify-center fixed top-0 bottom-0 right-0 left-0" @click.stop="closeModal()">
        <div class="h-auto flex-col px-6 py-3 space-y-4 flex items-center justify-center rounded-md" @click.stop>
            <div class="flex space-x-6">
                <div class="max-h-full overflow-y-auto flex flex-col" style="width: 400px; height: 460px">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">
                        {{ listType.listModalTitle || "" }}
                        <button class="bg-white cursor-pointer rounded-full h-6 w-6 justify-center items-center flex" @click="closeModal()">
                            <font-awesome-icon icon="times" class="text-gray-800" />
                        </button>
                    </div>
                    <div class="bg-white rounded-b-lg flex-1 h-full p-2 flex overflow-y-auto max-w-full">
                        <div v-if="loadingLists" class="flex justify-center items-center flex-1">
                            <Loader />
                        </div>

                        <ListView v-else :showSearch="true" :listItems="lists" class="max-w-full" :showBorder="false" @handleFilterResult="$emit('handleFilterResult', $event)">
                            <template #item="{ item }">
                                <FieldListItem v-if="listType && listType.type === 'field'" :activeElement="activeElement" :data="item" @handleSelect="handleSelect($event)" />
                                <BlockListItem v-if="listType && listType.type === 'block'" :activeElement="activeElement" :data="item" @handleSelect="handleSelect($event)" />
                            </template>
                        </ListView>
                    </div>
                </div>
                <div v-if="activeElement" class="max-h-full overflow-y-auto flex flex-col" style="width: 400px; height: 460px">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">{{ listType.permissionModalTitle || "" }}</div>
                    <div class="bg-white p-2 flex-1 h-full overflow-y-auto scroll-bar">
                        <BlockAndFieldPermissions :allRoles="allRoles" :isLoading="loadingStates.roles" @selectAllRoles="selectAllRoles($event)" @selectPermission="selectPermission($event)" />
                        <ReportVisibility @selectReportVisibility="selectReportVisibility($event)" :isLoading="loadingStates.visibility" :allReportsRoles="allReportsRoles" :value="roles_visibility" />
                    </div>
                    <div class="bg-white flex justify-end w-full px-2 pb-2 rounded-b-lg">
                        <Button @click="handleSubmit()" :disabled="isButtonDisabled" text="Add" type="primary" class="btn-lg" />
                    </div>
                </div>
                <div v-if="isGapValidation && listType && listType.type === 'field'" class="max-h-full overflow-y-auto flex flex-col" style="width: 400px; height: 460px">
                    <div class="bg-primary p-2 rounded-t-lg text-white-text font-semibold flex items-center justify-between">Gap Validation</div>
                    <div class="bg-white p-2 rounded-b-lg flex-1 h-full overflow-y-auto scroll-bar">
                        <GapValidation :gap_validation="gapValidationData" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Button from "@/components/button";
import ListView from "@shared/components/list-view";
import Loader from "@shared/loader";

import { fetchAllRoles, fetchReportVisibility } from "@/modules/field-editor/services.js";
import ReportVisibility from "./reportVisibility.vue";
import BlockAndFieldPermissions from "./BlockAndFieldPermissions.vue";
import GapValidation from "./gapValidation.vue";
//TODO: use gap Validation for add field Only
import BlockListItem from "./BlockListItem.vue";
import FieldListItem from "./FieldListItem.vue";

export default {
    name: "add-block-to-section-modal",
    components: {
        Button,
        ListView,
        Loader,
        ReportVisibility,
        BlockAndFieldPermissions,
        GapValidation,
        FieldListItem,
        BlockListItem,
    },
    props: {
        open: {
            type: Boolean,
            default: false,
        },
        lists: {
            type: Array,
            default: () => [],
        },
        loadingLists: {
            type: Boolean,
            default: false,
        },
        listType: {
            type: Object,
            default: () => {},
        },
        blockDetails:{
            type: Object,
            default: () => {}
        }
    },
    async mounted() {
        this.activeElement = null
        this.roles_visibility = null
    },
    data() {
        return {
            showModal: false,
            activeElement: null,
            allRoles: [],
            allReportsRoles: [],
            loadingStates: {
                roles: false,
                visibility: false,
            },
            gapValidationData: {
                validPeriod: 30,
                history: 12,
            },
            roles_visibility: null,
        };
    },
    computed: {
        // TODO: gap validation only for Field type
        isGapValidation() {
            if (this.activeElement?.meta) {
                const json_meta = JSON.parse(this.activeElement.meta);
                return json_meta.use_in_gap_validation === true;
            } else {
                return false;
            }
        },
        isAnyPermission() {
            return this.allRoles.some(el => {
                return Object.values(el.permissions).some(perm => !!perm);
            });
        },
        isButtonDisabled() {
            if(!this.activeElement || !this.roles_visibility || !this.isAnyPermission) return true
            else return false 

        },
    },
    methods: {
        async handleSelect(block) {
            this.allRoles = [];
            this.allReportsRoles = [];
            this.roles_visibility = null;

            if (!block || (this.activeElement && block.id === this.activeElement.id)) {
                this.activeElement = null;
            } else if (block) {
                this.activeElement = block;
                await this.fetchAllAvailableRoles();
                await this.fetchAvailableReportVisibility();
            }
        },

        async fetchAllAvailableRoles() {
            this.loadingStates.roles = true;
            let roles = await fetchAllRoles();
            this.allRoles = roles.map((role) => ({ ...role, permissions: { role_write: false, role_visible: false, role_read: false, role_mandatory: false }, select_all: false, report_visibility: "" }));
            this.loadingStates.roles = false;

            // result.data.fields;
        },
        async fetchAvailableReportVisibility() {
            this.loadingStates.visibility = true;
            this.allReportsRoles = await fetchReportVisibility();
            this.loadingStates.visibility = false;
        },
        selectAllRoles(role) {
            let permissions = { role_write: role.select_all, role_visible: role.select_all, role_read: role.select_all, role_mandatory: role.select_all };
            this.allRoles = this.allRoles.map((el) => {
                if (el.id === role.id) {
                    return { ...el, permissions };
                }
                return el;
            });
        },
        selectPermission(role) {
            this.allRoles = this.allRoles.map((el) => {
                if (el.id === role.id) {
                    return { ...el, permissions: this.handleChangePermission(role.permissions), select_all: this.checkAllPermissionsSelected(role.permissions) };
                }
                return el;
            });
        },
        handleChangePermission(permission) {
            let newPermission = permission;
            if (newPermission.role_mandatory) {
                newPermission.role_read = true;
                newPermission.role_write = true;
                newPermission.role_visible = true;
            } else if (newPermission.role_write) {
                newPermission.role_read = true;
                newPermission.role_visible = true;
            } else if (newPermission.role_read) {
                newPermission.role_visible = true;
            }
            return newPermission;
        },
        checkAllPermissionsSelected(permission) {
            return !!(permission.role_write && permission.role_visible && permission.role_read && permission.role_mandatory);
        },
        selectReportVisibility(visibility) {
            this.roles_visibility = visibility;
        },
        handleSubmit() {
            let roles = [];
            this.allRoles.forEach((el) => {
                let payload_permision = {
                    title: el.title,
                    role_id: el.id,
                    role_read: el.permissions.role_read,
                    role_write: el.permissions.role_write,
                    role_visible: el.permissions.role_visible,
                    role_mandatory: el.permissions.role_mandatory,
                };
                roles.push(payload_permision);
            });
            let payload = {
                tenant_field_id: this.activeElement.id,
                report_visibility: this.roles_visibility?.id,
                use_in_gap_validation: this.isGapValidation,
                roles: roles,
            };
            if (this.isGapValidation) {
                payload = { 
                    ...payload, 
                    gap_history: this.gapValidationData.history || 12, 
                    valid_gap: this.gapValidationData.validPeriod || 30 
                };
            }

            let data = {
                payload, 
                field: this.activeElement
            }

            this.$emit("handleAddFieldToBlock", data);
            this.handleSelect(this.activeElement);
        },

        closeModal() {
            this.handleSelect(this.activeElement);
            this.$emit("close");
        }
    },
};
</script>
<style lang="scss" scoped>
.addblocktosection {
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
}
</style>
